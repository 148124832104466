@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: "Avenir-2";
    src: url("../fonts/avenir/AvenirLTStd-Roman.otf");
  }

  @font-face {
    font-family: "Avenir-1";
    src: url("../fonts/avenir/AvenirLTStd-Book.otf");
  }

  @font-face {
    font-family: "Avenir-3";
    src: url("../fonts/avenir/AvenirLTStd-Black.otf");
  }

  @font-face {
    font-family: "Avenir-Next-1";
    src: url("../fonts/avenir/AvenirNextLTPro-Regular.otf");
  }

  @font-face {
    font-family: "Avenir-Next-2";
    src: url("../fonts/avenir/AvenirNextLTPro-Bold.otf");
  }

  @font-face {
    font-family: "Avenir-Next-italic";
    src: url("../fonts/avenir/AvenirNextLTPro-It.otf");
  }

  @font-face {
    font-family: "Avenir-Next-Cn-1";
    src: url("../fonts/avenir/AvenirNextLTPro-DemiCn.otf");
  }

  @font-face {
    font-family: "Avenir-Next-Cn-2";
    src: url("../fonts/avenir/AvenirNextLTPro-BoldCn.otf");
  }

  @font-face {
    font-family: "Circular-Std-Book";
    src: url("../fonts/circular/Circular Std Book.ttf");
  }

  @font-face {
    font-family: "Circular-Std-Medium";
    src: url("../fonts/circular/Circular Std Medium.ttf");
  }

  @font-face {
    font-family: "Circular-Std-3";
    src: url("../fonts/circular/Circular Std Bold.ttf");
  }

  @font-face {
    font-family: "Circular-Std-bold-italic";
    src: url("../fonts/circular/Circular Std Bold Italic.ttf");
  }

  @font-face {
    font-family: "Circular-Std-Black";
    src: url("../fonts/circular/Circular Std Black.ttf");
  }

  @font-face {
    font-family: "Circular-Air";
    src: url("../fonts/circular/CircularAir-Light.ttf");
  }

  @font-face {
    font-family: "Gilroy-1";
    src: url("../fonts/gilroy/Gilroy-Light.ttf");
  }

  @font-face {
    font-family: "Gilroy-2";
    src: url("../fonts/gilroy/Gilroy-Regular.ttf");
  }

  @font-face {
    font-family: "Gilroy-3";
    src: url("../fonts/gilroy/Gilroy-Medium.ttf");
  }

  @font-face {
    font-family: "Gilroy-4";
    src: url("../fonts/gilroy/Gilroy-Bold.ttf");
  }

  @font-face {
    font-family: "Gilroy-5";
    src: url("../fonts/gilroy/Gilroy-ExtraBold.otf");
  }

  @font-face {
    font-family: "Gilroy-6";
    src: url("../fonts/gilroy/Gilroy-Heavy.ttf");
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input,
textarea {
  -webkit-user-select: text;
  -khtml-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.banner_1 {
  background-color: #a2d6a7;
}

.banner_1_button {
  background-color: #8bcc91;
}
.banner_2 {
  background-color: #cdd7ff;
}

.banner_2_button {
  background-color: #bdcaff;
}

.otp-input {
  width: 4em !important;
}

.otp-input-vacc {
  width: 3em !important;
}

/* Remove default story changing element */
.stories-container > div > div:nth-child(3) {
  display: none !important;
}

/* Overriding styles from react-pdf */
.pdf-container,
.react-pdf__Document,
.react-pdf__Page,
.react-pdf__Page__canvas {
  height: 600px !important;
  width: 400px !important;
}
@media all and (max-width: 430px) {
  .pdf-container,
  .react-pdf__Document,
  .react-pdf__Page,
  .react-pdf__Page__canvas {
    height: 550px !important;
    width: 350px !important;
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.unstyled::-webkit-inner-spin-button,
.unstyled::-webkit-calendar-picker-indicator {
  opacity: 0;
  -webkit-appearance: none;
}

/* Temporary. Tailwind not working */
.patient-name,
.patient-name:focus,
.patient-name:active {
  outline: none;
  border: none;
}

/* Overriding styles from spring-bottom-sheet */
.upload [data-rsbs-header] {
  background-color: #f9faff;
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
}

/* Safari border radius fix */
input {
  -webkit-border-radius: 0;
  border-radius: 0;
}

/* TODO: Conver everything below this to tailwind :(*/

.share-delete:active {
  background-color: rgba(239, 242, 255, 0.8);
}

/* blue bg onclick */

* {
  -webkit-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

.order_now {
  width: 18rem !important;
}

.overflow_style {
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}
